import Slider from "react-slick";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { IClassroom } from "../../src/interfaces";
import ClassroomCard from "../Academy/ClassroomCard";
import { useLoginContext } from "../../hooks";

interface ClassroomsSliderProps {
  classrooms: IClassroom[];
  number: number;
  theme?: "light" | "dark";
  hideCompleted?: boolean;
  autoSlide?: boolean;
  className?: string;
}

function ClassroomsSlider({
  classrooms,
  number,
  theme = "light",
  hideCompleted = false,
  autoSlide = true,
  className = "",
}: ClassroomsSliderProps) {
  const { isReadyToQuest, userCompletedQuestsAsynchronous } = useLoginContext();
  const [orderedClassrooms, setOrderedClassrooms] =
    useState<IClassroom[]>(classrooms);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoSlide,
    autoplaySpeed: 3000,
    arrows: false,
  };

  useEffect(() => {
    if (isReadyToQuest) {
      let completedClassroomsCount = 0;
      const classroomsWithCompletion = classrooms.map((classroom) => {
        let count = 0;
        classroom.quests.forEach((quest) => {
          if (userCompletedQuestsAsynchronous.includes(quest.collectionId)) {
            count += 1;
          }
        });

        const completed = count === classroom.quests.length;

        if (completed) {
          completedClassroomsCount += 1;
        }

        return {
          ...classroom,
          completed: completed,
        };
      });

      if (completedClassroomsCount === classrooms.length) {
        setOrderedClassrooms(classroomsWithCompletion);
      } else if (hideCompleted) {
        setOrderedClassrooms(
          classroomsWithCompletion.filter((c) => !c.completed)
        );
      } else {
        setOrderedClassrooms(
          classroomsWithCompletion.sort(
            (a, b) => Number(a.completed) - Number(b.completed)
          )
        );
      }
    } else {
      setOrderedClassrooms(classrooms);
    }
  }, [isReadyToQuest, userCompletedQuestsAsynchronous, classrooms]);

  return (
    <Slider
      {...settings}
      className={twMerge(
        `w-full ${
          theme === "light" ? "black-dots black-dots-active" : "white-dots"
        }`,
        className
      )}
    >
      {orderedClassrooms.slice(0, number).map((classroom) => (
        <div
          className="flex items-center justify-center w-full"
          key={classroom.title}
        >
          <ClassroomCard classroom={classroom} />
        </div>
      ))}
    </Slider>
  );
}

export default ClassroomsSlider;
