import React, { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "next-i18next";
import { useLoginContext } from "../../hooks";

interface QuestSucceedProps {
  completed: number;
  total: number;
  widthInPx?: number;
  className?: string;
}

function QuestSucceed({
  completed,
  total,
  widthInPx = 0,
  className = "",
}: QuestSucceedProps) {
  const { t } = useTranslation("common");
  const { isReadyToQuest } = useLoginContext();

  const [succeedWidth, setSucceedWidth] = useState<number>(0);
  const [classroomCompleted, setClassroomCompleted] = useState<boolean>(false);
  const [progressBarWidth, setProgressBarWidth] = useState<number>(widthInPx);

  const progressBarRef = useCallback((node) => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries && entries[0]) {
        const { clientWidth } = entries[0].target;
        setProgressBarWidth(clientWidth);
      }
    });

    if (node && widthInPx === 0) {
      setProgressBarWidth(node.clientWidth);
      resizeObserver.observe(node);
    }
  }, []);

  useEffect(() => {
    if (isReadyToQuest) {
      if (total > 0) {
        const ratio = completed / total;
        if (widthInPx > 0) {
          setSucceedWidth(ratio * widthInPx);
        } else {
          setSucceedWidth(ratio * progressBarWidth);
        }
      }
    } else {
      setSucceedWidth(0);
    }
  }, [completed, total, progressBarWidth]);

  useEffect(() => {
    setClassroomCompleted(completed === total);
  }, [completed, total]);

  useEffect(() => {
    if (!isReadyToQuest) {
      setClassroomCompleted(false);
    }
  }, [isReadyToQuest]);

  return (
    <div
      className={twMerge(
        ` ${classroomCompleted ? "text-green" : "text-white"}`,
        className
      )}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <div className={`${classroomCompleted ? "" : "hidden"}`}>
            <img
              src="/icons/check-circle-green.svg"
              alt="check"
              className="h-4 w-4"
            />
          </div>
          {isReadyToQuest ? (
            <div className="tagText">
              {completed}/{total}{" "}
              {t("status.questsSucceed", { count: completed })}
            </div>
          ) : (
            <div className="tagText">
              {total} {t("status.questsToComplete", { count: total })}
            </div>
          )}
        </div>
        <div className="relative">
          <div
            ref={progressBarRef}
            className="h-2 rounded-3xl bg-gray-700"
            style={{
              width: `${widthInPx > 0 ? `${widthInPx}px` : "auto"}`,
            }}
          />
          <div
            className={`absolute top-0 h-2 rounded-3xl ${
              classroomCompleted ? "bg-green" : "bg-primary"
            }`}
            style={{ width: `${succeedWidth}px` }}
          />
        </div>
      </div>
    </div>
  );
}

export default QuestSucceed;
