import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { IClassroom } from "../../src/interfaces";
import Tag from "./Tag";
import QuestSucceed from "./QuestsSucceed";
import { useLoginContext } from "../../hooks";
import Difficulty from "../Difficulty";

interface ClassroomCardProps {
  classroom: IClassroom;
}

function ClassroomCard({ classroom }: ClassroomCardProps) {
  const router = useRouter();
  const { isReadyToQuest, userCompletedQuestsAsynchronous } = useLoginContext();
  const [completedQuests, setCompletedQuests] = useState<number>(0);

  useEffect(() => {
    if (isReadyToQuest) {
      let count = 0;
      classroom.quests.forEach((quest) => {
        if (userCompletedQuestsAsynchronous.includes(quest.collectionId)) {
          count += 1;
        }
      });

      setCompletedQuests(count);
    } else {
      setCompletedQuests(0);
    }
  }, [isReadyToQuest, userCompletedQuestsAsynchronous, classroom]);

  return (
    <button
      type="button"
      onClick={() => router.push(`/academy/${classroom.slug}`)}
      className="group relative h-[374px] w-full mx-auto rounded-xl flex flex-col-reverse md:flex-row place-content-between text-left text-white p-4 overflow-hidden"
    >
      <div
        style={{
          backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%), url(${classroom.horizontalBannerUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
        className="absolute top-0 left-0 w-full h-full duration-150 ease-linear group-hover:scale-110"
      />
      <div className="w-full flex flex-col place-content-between md:h-[206px] md:w-[344px] self-start md:self-center md:ml-20 z-10">
        <div className="display-5 md:text-display-4 uppercase ml-1 md:ml-0">
          {classroom.title}
        </div>
        <div>
          <Difficulty
            difficulty={classroom.difficulty}
            className="bodyText mt-3 ml-1 md:ml-0 mb-6"
          />
          <QuestSucceed
            completed={completedQuests}
            total={classroom.quests.length}
          />
        </div>
      </div>
      <Tag
        className="z-10 self-end md:self-stretch md:m-6"
        text={classroom.topic}
      />
    </button>
  );
}

export default ClassroomCard;
