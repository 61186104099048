import React from "react";
import { useTranslation, Trans } from "next-i18next";
import Button from "../Button";

function AcademyArea() {
  const { t } = useTranslation("common");

  return (
    <div className="w-full flex justify-center py-20 z-20 relative bg-black">
      <div className="max-w-[1440px] w-full md:px-[65px] px-4 flex md:flex-row flex-col md:justify-between gap-10 items-center">
        <img
          className="md:w-[350px] lg:w-auto w-full"
          src="/images/academy.png"
          alt="academy"
        />
        <div className="text-white font-['Inter'] lg:w-[632px] md:w-[400px] w-full">
          <div className="bodyText uppercase text-purple">
            {t("academyArea.tagline")}
          </div>
          <div className="mt-6">
            <img
              src="/icons/Academy-logo.svg"
              alt=""
              className="md:w-[280px] w-[232px]"
            />
          </div>
          <div className="mt-3 display-5 md:text-display-3 max-w-[501px] w-full uppercase">
            <Trans
              components={{
                colored: <span className="text-purple" />,
              }}
              i18nKey="common:academyArea.title"
            />
          </div>
          <div className="mt-[32px] bodyText md:max-w-[482px]">
            {t("academyArea.description")}
          </div>
          <div className="md:w-[317px] w-full md:h-[76px] h-[56px] mt-8">
            <Button
              href="https://www.ledger.com/academy"
              label={t("prepareToWin")}
              type="withWhiteBorder"
              svgIcon="ExternalLink"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcademyArea;
