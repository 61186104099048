interface SocialIconsProps {
  hasOpacity?: boolean;
  color?: "white" | "black";
}
function SocialIcons(props: SocialIconsProps) {
  const { hasOpacity = false, color = "white" } = props;
  const socials = [
    { name: "facebook", href: "https://www.facebook.com/Ledger/" },
    { name: "instagram", href: "https://www.instagram.com/ledger/" },
    { name: "twitter", href: "https://twitter.com/Ledger" },
    {
      name: "linkedin",
      href: "https://www.linkedin.com/company/ledgerhq/posts/?feedView=all",
    },
    { name: "discord", href: "https://discord.com/invite/ledger" },
  ];

  return (
    <div className="flex gap-3 mt-[16px]">
      {socials.map((social) => (
        <a
          key={social.name}
          href={social.href}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={`/icons/Homepage/${social.name}${
              color === "black" ? "-black" : ""
            }.svg`}
            className={`${hasOpacity ? "opacity-50" : ""}`}
            alt=""
          />
        </a>
      ))}
    </div>
  );
}

export default SocialIcons;
