import { Link } from "react-scroll";
import { twMerge } from "tailwind-merge";

interface LinkChildrenProps {
  label: string;
  svgIcon?:
    | "Arrow-right" /* small black arrow */
    | "Arrow-left" /* small black arrow */
    | "Arrowright" /* white right arrow */
    | "arrow-left-white" /* white left arrow */
    | "arrow-bottom-black" /* black bottom arrow */
    | "arrow-bottom-white" /* white bottom arrow */
    | "ExternalLink";
  jsxIcon?: JSX.Element;
  inverted?: boolean;
  disabled?: boolean;
  variant?: "black" | "white";
}

function LinkChildren(props: LinkChildrenProps) {
  const {
    label,
    svgIcon = null,
    jsxIcon = null,
    inverted = false,
    disabled = false,
    variant = "white",
  } = props;

  const svgTransition = () => {
    if (!disabled) {
      if (inverted) return "transition-left";
      return "transition-right";
    }
    return "";
  };
  const svgColor = variant === "black" ? "invert" : "";
  const disabledStyless = disabled ? "opacity-50" : "";
  const labelTransition = () => {
    if (!disabled && (svgIcon || jsxIcon)) {
      if (inverted) return "transition-right";
      return "transition-left";
    }
    return "";
  };

  return (
    <>
      <span
        className={`capitalize bodyText ${labelTransition()} ${disabledStyless}`}
      >
        {label}
      </span>
      {svgIcon && (
        <img
          src={`/icons/Homepage/${svgIcon}.svg`}
          alt=""
          className={`${svgTransition()} ${disabledStyless} ${svgColor}`}
        />
      )}
      {jsxIcon && (
        <div data-testid="jsx-element" className={svgTransition()}>
          {jsxIcon}
        </div>
      )}
    </>
  );
}

export interface Props extends LinkChildrenProps {
  href?: string;
  onClick?: () => void;
  link?: string;
  underline?: boolean;
  className?: string;
}

function ButtonLink(props: Props) {
  const {
    label,
    svgIcon,
    jsxIcon,
    inverted,
    disabled,
    href,
    onClick,
    link,
    variant,
    underline = false,
    className = "",
  } = props;

  const buttonClasses = () => {
    let classes: string = "";
    if (underline) classes += " underline hover:no-underline";
    if (variant === "black") classes += " text-black";
    else if (variant === "white") classes += " text-white";
    if (inverted) classes += " flex-row-reverse";
    return classes;
  };

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={twMerge(
          `group cursor-pointer flex items-center gap-1 font-semibold underline transition-left`,
          className
        )}
      >
        <LinkChildren
          label={label}
          svgIcon={svgIcon}
          jsxIcon={jsxIcon}
          inverted={inverted}
          disabled={disabled}
          variant={variant}
        />
      </a>
    );
  }

  if (link) {
    return (
      <Link
        to={link}
        smooth
        className={twMerge(
          `group cursor-pointer flex items-center gap-3 font-semibold underline transition-left w-fit`,
          buttonClasses(),
          className
        )}
        data-testid="react-scroll"
      >
        <LinkChildren
          label={label}
          svgIcon={svgIcon}
          jsxIcon={jsxIcon}
          inverted={inverted}
          disabled={disabled}
          variant={variant}
        />
      </Link>
    );
  }

  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={twMerge(
          `group bottom-0 leading-6 w-max flex items-center ${buttonClasses()} gap-3.5`,
          className
        )}
        disabled={disabled}
      >
        <LinkChildren
          label={label}
          svgIcon={svgIcon}
          jsxIcon={jsxIcon}
          inverted={inverted}
          disabled={disabled}
          variant={variant}
        />
      </button>
    );
  }

  return (
    <div
      className={twMerge(
        `group bottom-0 w-max flex items-center ${buttonClasses()} gap-3.5`,
        className
      )}
    >
      <LinkChildren
        label={label}
        svgIcon={svgIcon}
        jsxIcon={jsxIcon}
        inverted={inverted}
        disabled={disabled}
        variant={variant}
      />
    </div>
  );
}
export default ButtonLink;
