import React from "react";
import { twMerge } from "tailwind-merge";

interface TagProps {
  text: string;
  className?: string;
}

function Tag({ text, className = "" }: TagProps) {
  return (
    <div
      className={twMerge(
        "h-fit w-fit border-2 border-white rounded-full px-4 py-3 text-white tagText uppercase",
        className
      )}
    >
      {text}
    </div>
  );
}

export default Tag;
