import React from "react";
import { useTranslation } from "next-i18next";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { twMerge } from "tailwind-merge";

interface DifficultyProps {
  difficulty: number;
  total?: number;
  className?: string;
}

const Difficulty = ({
  difficulty,
  total = 3,
  className = "",
}: DifficultyProps) => {
  const { t } = useTranslation("common");

  const difficultyStars = [];
  for (let i = 0; i < total; i += 1) {
    if (i < difficulty) {
      difficultyStars.push(<StarIcon key={i} />);
    } else {
      difficultyStars.push(<StarBorderIcon key={i} />);
    }
  }

  return (
    <div className={twMerge(`flex flex-row items-center gap-2`, className)}>
      <div>{t("difficulty")} :</div>
      {/* Quest difficulty */}
      <div className="flex flex-row items-center gap-1 text-primary">
        {difficultyStars}
      </div>
    </div>
  );
};

export default Difficulty;
